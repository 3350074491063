import React, { useState, useEffect } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { Box, Typography } from "@mui/material/";
import axios from "axios";
import moment from "moment";
import "./css/info.css";

// "/v1/search.php?key=YOUR_ACCESS_TOKEN&q=SEARCH_STRING&format=json"

const plurl =
  "https://eu1.locationiq.com/v1/reverse.php?key=pk.5bb41df99bb77e1a059a77cfd4a61d15";

const MyInfoWindow = ({ device, geo, onMarkerClick }) => {
  const [address, setAddress] = useState("");


  

  useEffect(() => {
    let url = plurl + "&lat=" + geo.lat + "&lon=" + geo.lng + "&format=json";

    axios
      .get(url)
      .then((response) => {
        if (response.data.display_name) {
          setAddress((old) => response.data.display_name);
        }
      })
      .catch((err) => console.log(err));
  }, [geo]);

  const stop_duration = (dateStr) => {
    let updateTime = new Date(dateStr);
    let currentDate = new Date();
    let diff = Math.floor(
      (currentDate.getTime() - updateTime.getTime()) / 1000
    );

    if (diff <= 10) {
      return "NOW";
    } else if (diff > 10 && diff < 60) {
      return diff + " seconds ago";
    } else if (diff > 60 && diff < 3600) {
      let min = Math.floor(diff / 60);
      let sec = diff % 60;
      return min + " min " + sec + " secs ago";
    } else if (diff > 3600 && diff < 3600 * 24) {
      let hr = Math.floor(diff / 3600);
      let rem = diff % 3600;
      let min = Math.floor(rem / 60);
      let sec = rem % 60;
      return hr + " hrs " + min + " min " + sec + " secs ago";
    } else {
      let day = Math.floor(diff / (3600 * 24));
      let hrRem = diff % (3600 * 24);
      let hr = Math.floor(hrRem / 3600);
      let rem = hrRem % 3600;
      let min = Math.floor(rem / 60);
      let sec = rem % 60;
      return day + " days " + hr + " hrs " + min + " min " + sec + " secs ago";
    }

    // return diff
  };

  return (
    <InfoWindow
      position={{ lat: geo.lat, lng: geo.lng }}
      onCloseClick={() => onMarkerClick(false)}
    >
      <Box className="main_box">
        <p className="title_field">ID:</p>
        <p className="title_value">{device.id}</p>

        <p className="title_field">Code:</p>
        <p className="title_value">{device.code}</p>

        <p className="title_field">
          Engine/Registration Number:
        </p>
        <p className="title_value">
          {device.registration_number}
        </p>

        <p className="title_field">Current Location:</p>
        <p className="title_value">{address}</p>

        <p className="title_field">Ignition:</p>
        <p className="title_value">{geo.acc}</p>

        <p className="title_field">Speed:</p>
        <p className="title_value">{geo.speed} KMPH</p>
        {geo.charging && (
          <p className="title_field">Charging:</p>
        )}

        {geo.charging && (
          <p className="title_value">{geo.charging}</p>
        )}

        {geo.fuel_line && (
          <p className="title_field">Relay State:</p>
        )}

        {geo.fuel_line && (
          <p className="title_value">{geo.fuel_line}</p>
        )}

        {geo.pto_io_status && (
          <p className="title_field">PTO IO STATUS:</p>
        )}

        {geo.pto_io_status && (
          <p className="title_value">
            {geo.pto_io_status === "1" ? "ON" : "OFF"}
          </p>
        )}

        {geo.over_speed && (
          <p className="title_field">OVERSPEED:</p>
        )}

        {geo.over_speed && (
          <p className="title_value">
            {geo.over_speed === "1" ? "TRUE" : "FALSE"}
          </p>
        )}

        {geo.temperature && (
          <p className="title_field">TEMPERATURE:</p>
        )}

        {geo.temperature && (
          <p className="title_value">{geo.temperature}</p>
        )}

        {geo.number_of_satellite && (
          <p className="title_field">NO OF SATELLITE:</p>
        )}

        {geo.number_of_satellite && (
          <p className="title_value">
            {geo.number_of_satellite} Nos
          </p>
        )}

        {geo.voltage_level && (
          <p className="title_field">VOLTAGE LEVEL:</p>
        )}

        {geo.voltage_level && (
          <p className="title_value">{geo.voltage_level}</p>
        )}

        <p className="title_field">Last Update:</p>

        <p className="title_value">
          {geo.update_time
            ? moment(geo.update_time).format("MMMM DD YYYY, h:mm:ss a")
            : "undefined"}
        </p>

        <p className="title_field">Stop Duration:</p>

        <p className="title_value">
          {geo.update_time ? stop_duration(geo.update_time) : "undefined"}
        </p>
      </Box>
    </InfoWindow>
  );
};

export default MyInfoWindow;
