import { SET_DEVICE } from "../constant/action_type";

const device_reducer = (state = [], action) => {
  if (action.type === SET_DEVICE) {
    return action.payload;
  } else {
    return state;
  }
};

export default device_reducer;
