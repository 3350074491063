import React,{useEffect,useState} from 'react'
import { GoogleMap, useJsApiLoader,TrafficLayer } from '@react-google-maps/api';
import {withFirebase} from '../Firebase'
import LocationMarker from './location_marker'

const containerStyle = {
  width: '100vw',
  height: '100vh'
};


function GMap({firebase}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ"
  })

  const [center,setCenter] = useState({
    lat: 23.44,
    lng: 90.444,
  })

  // console.log(window.innerWidth,"Widths")

  const device = JSON.parse(localStorage.getItem("Device"));


  useEffect(() => {

   

    const ref = firebase.singleDeviceRef(device.id).child("geo");
  
    ref.once('value',snapshot=>{
      let geo = snapshot.val()
  
      if(center.lat!= geo.lat || center.lng !=geo.lng){
        setCenter(geo)
      }
    })
  
    
  }, [firebase]);

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
      >
        <LocationMarker device={device} />
        <TrafficLayer />
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
  ) : <></>
}

export default withFirebase(React.memo(GMap))


// /* global google */

// import React,{useEffect,useState} from "react";

// import { Box } from "@mui/material";
// import { GoogleMap, LoadScript, TrafficLayer,useJsApiLoader } from "@react-google-maps/api";
// import LocationMarker from "./locationMarker";
// import {withFirebase} from '../Firebase'

// const GMap = ({ device,firebase }) => {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyDTeb6CeMmkL3UlILfLET6vuIpOnhOBTRQ"
//   })

//   const [center,setCenter] = useState({
//     lat: 23.34,
//     lng: 90.34,
//   })


//   const containerStyle = {
//     height: "100%",
//     width: "100%",
//   };


//   useEffect(() => {

//     console.log(firebase)

//     const ref = firebase.singleDeviceRef(device.id).child("geo");
//     ref.once('value',snapshot=>{
//       let geo = snapshot.val()
//       console.log(geo)
//       if(center.lat!= geo.lat || center.lng !=geo.lng){
//         setCenter(geo)
//       }
//     })

    
//   }, []);

//   const onload =()=>{
//     console.log("On Load Called")
//     console.log("On Load Called")
//   }
//   return (

//     <LoadScript googleMapsApiKey="AIzaSyDqLAok8VFxErXTvGp_uoWZFRygnOeILoQ">
//         <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17} onLoad={onload}>
//           {/* <LocationMarker device={device} />
//           <TrafficLayer /> */}
//         </GoogleMap>
//       </LoadScript>
//   );

 
  
 
// };

// export default withFirebase(GMap);
