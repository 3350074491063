import React, { useEffect, useState } from "react";
import { Box, Button,Typography,Snackbar,Alert } from "@mui/material/";
import axios from 'axios'
import {DEVICE_URL} from '../utils/urls'
// import { connect } from "react-redux";

import TextField from "@mui/material/TextField";

// import { fetchdevice } from "../redux/action/device_action";
import { useHistory } from "react-router";
import "./css/login.css";

const Login = () => {

  const [snackstate, setsnackState] = React.useState({
    open: false,
    vertical: 'center',
    horizontal: 'center',
    message:"This is a Message"
  });

  const { vertical, horizontal, open,message } = snackstate;
 

  const history = useHistory();
  const [userdata, setUserdata] = useState({
    code: "",
    password: "",
  });

  const [visible, setvisible] = useState(true);

  const handleChange = (e) => {
    const newUserdata = { ...userdata };
    newUserdata[e.target.name] = e.target.value;

    newUserdata.code !== "" && newUserdata.password !== ""
      ? setvisible(false)
      : setvisible(true);
    setUserdata(newUserdata);
  };


  const handleClose = () => {
    setsnackState({ ...snackstate, open: false });
  };

  const loginClicked = () => {
    // fetchdevice(userdata);
    // // history.push("/");

    axios.post(DEVICE_URL,userdata)
    .then(response=>{
      if(response.status===200){
        if(response.data){
          // console.log(response.data)
          localStorage.setItem("Device", JSON.stringify(response.data));
          history.push("/")
        }else{
          setsnackState({ ...snackstate, open: true,message:"Code doesn't Exist. Please Contact with Customer Care" });
        }

      }else{
        setsnackState({ ...snackstate, open: true,message:"Server Error!!" });
      }
    })
  };

  // useEffect(() => {
  //   if (device) {
  //     history.push("/");
  //   }
  // }, [device]);

  return (
    <div className="wrapper">
        <div className="main"></div>
        <div className="login_content">
        <Box
          display="flex"
          flexDirection="column"
          style={{padding:20,marginTop:30}}
         
        >
          <Typography variant="h4">
            LOGIN
          </Typography>
          <TextField
            style={{marginTop:20}}
            id="code"
            label="Vehicle Code"
            name="code"
            value={userdata.code}
            onChange={handleChange}
          />
          <TextField
           style={{marginTop:10}}
            id="password"
            label="Password"
            name="password"
            type="password"
            value={userdata.password}
            onChange={handleChange}
          />

          <Box display="flex" justifyContent="end">
          <Button
            style={{marginTop:10}}
            variant="contained"
            color="primary"
            onClick={loginClicked}
            disabled={visible}
          >
            Login
          </Button>
          </Box>

         
        </Box>
        </div>

        <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
    {message}
  </Alert>
      </Snackbar>
        
    </div>
  
  );
};

// const mapStateToProps = (state) => {
//   return {
//     ...state,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     fetchdevice: (device) => dispatch(fetchdevice(device)),
//   };
// };

export default Login;
