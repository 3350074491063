import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Paper, Typography,Card, Divider } from "@mui/material";

import {CChartBar} from '@coreui/react-chartjs'
// import TripReport from "./table";
// import Report from "./report";
import StatusTable from "./status";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import axios from "axios";
import { grey, red } from "@mui/material/colors";
import { DAILYDATA } from "../../utils/urls";
import TitleBar from "../title_bar";
import DailyBottomNavigation from "../daily-bottom-navigation";
import '../css/daily-report.css'
import RouteImage from '../../images/route.png'
import TimeImage from '../../images/time.png'
import FuelImage from '../../images/fuel.png'
import {getFormattedTime,timeFormat,nth,getFuel} from '../../utils/hours'


const TopCard=({image,value})=>{

  return(
    <Card variant="outlined" className="card" >
    <div className="daily-main">
      <div className="daily-image-container">
       <img src={image} alt="Route" width={60} height={60} />
      </div>
      <Typography style={{width:"100%",fontSize:10}} variant="button" align="center">{value}</Typography>

    </div>

  </Card>
  )
}


const TripItem=({item})=>{

  // console.log(item)

  return(
    <Card>
    <div
      style={{
        display: "flex",
        // justifyContent: "space-between",
        fontSize:12,
        alignItems: "center",
        padding: 10,
      }}
    >
      <p style={{flex:1,textAlign:"start"}}>{item.status}</p>
      <div style={{flex:1,textAlign:"start"}}>
        <p>{timeFormat(item.start)}</p>
        <p>{timeFormat(item.end)}</p>
      </div>
      <p style={{flex:1,textAlign:"start"}}>{getFormattedTime(item.duration)}</p>
      <p style={{flex:1,textAlign:"end"}}>{item.distance} KM</p>
    </div>
  </Card>
  )

}


const TripReport=({trip_report})=>{

  return(
    <div>
      {trip_report && trip_report.map((x,i)=>{
        return(
          <div key={i} >
          <TripItem item={x}/>
          {i!==trip_report.length-1 && <Divider/>}
        </div>
        )
      })}
    </div>
  )
}

const HourlyItem = ({item})=>{

  return(
    <Card>
    <div
      style={{
        display: "flex",
        // justifyContent: "space-between",
        fontSize:12,
        alignItems: "center",
        padding: 10,
      }}
    >
      <p style={{flex:1,textAlign:"start"}}>{(item._id+1)+nth(item._id+1)+" Hour"}</p>
      <div style={{flex:1,textAlign:"start"}}>
        <p>{timeFormat(item.start)}</p>
        <p>{timeFormat(item.end)}</p>
      </div>
      {/* <p style={{flex:1,textAlign:"start"}}>{getFormattedTime(item.duration)}</p> */}
      <p style={{flex:1,textAlign:"end"}}>{item.distance} KM</p>
    </div>
  </Card>
  )

}


const HourlyReport=({hourly_report})=>{
  return(
    <div>
      {hourly_report && hourly_report.map((x,i)=>{
        return(
          <div key={i} >
          <HourlyItem item={x}/>
          {i!==hourly_report.length-1 && <Divider/>}
        </div>
        )
      })}
    </div>
  )

}


const BarChart = ({hourly_report})=>{
  // let height = window.innerWidth>400?400:500;

  const getHeight=()=>{
    if(window.innerWidth<=400){
      return 500
    }else if(window.innerWidth>400 && window.innerWidth<=720){
      return 400
    }else{
      return 300
    }
  }

  // console.log(getHeight(),"HEight")

  const labels = hourly_report.map(x=>(x._id+1)+nth(x._id+1))
  const data = hourly_report.map(x=>x.distance)

  return(
    <CChartBar 
    style={{maxHeight:getHeight()}}
    height={getHeight()}
    // height="500"
    data={{

      labels: labels,
  
      datasets: [
  
        {
  
          label: 'Hourly Travel Distance',
  
          backgroundColor: '#f87979',
  
          data:data,
  
        },
  
      ],
  
    }}
  
    labels="months"
    />
  )
}






const DailyReport = () => {
  // const [reportvisible, setReportvisible] = useState(true);
  const [state,setState] = useState(0)
  const [date, setDate] = React.useState(new Date());
  const [dailyData, setDailyData] = useState({});

  const handleChange = (newValue) => {
    setDate(newValue);
  };
  const Device = JSON.parse(localStorage.getItem("Device"));
  console.log(Device.vehicle_type);


  const calendarClick=()=>{

  }

  const fetchDailyData = () => {
    console.log(date,"Date")
    let data = {
      device_id: Device.id,
      device_type: Device.vehicle_type,
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };

    axios
      .post(DAILYDATA, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log(response.data)
        setDailyData(response.data);
      })

      .catch((Err) => {
        console.log(Err);
      });
  };

  useEffect(() => {
    fetchDailyData();
  }, [date]);
  useEffect(() => {
    if (dailyData != null) {
    }
  });

  // console.log(dailyData.hourly_report);

  return (
    <div className="main-container">
      <TitleBar
        title="Daily Report"
        calendarVisible={true}
        calendarClick={calendarClick}
        date={date}
        setDate={setDate}
      />

      <div
        style={{ position: "fixed", left: 0, right: 0, top: 64, bottom: 56 }}
      >
        <div className="daily-info-container">
          <TopCard image={RouteImage} value={dailyData.total_distance+" KM"} />
          <TopCard image={TimeImage} value={getFormattedTime(dailyData.running_time)} />
          <TopCard image={FuelImage} value={getFuel(dailyData.total_distance)} />
        </div>
        <div className="daily-main-content">

          {state===0 && dailyData.trip_report && <TripReport trip_report={dailyData.trip_report}/>}
          {state===1 && dailyData.hourly_report && <HourlyReport hourly_report={dailyData.hourly_report}/>}
          {state===2 && dailyData.hourly_report && <BarChart hourly_report={dailyData.hourly_report}/>}
        
         
        </div>
      </div>
      <DailyBottomNavigation value={state} setValue={setState} />
    </div>
   
  );
};

export default DailyReport;
