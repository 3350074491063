import React, { useState, useEffect } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { MONTHLY_LOCATIONS_URL,MONTHLY_FILE_URL } from "../utils/urls";
import axios from "axios";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import "./css/monthly-report.css";

import TitleBar from "./title_bar";



const getFuel =(distance)=>{
  let device = JSON.parse(localStorage.getItem("Device"))
  if(device.milage){
    let d = distance/1000
    return (d/device.milage).toFixed(2)+" Lit"
  }else{
    return "Milage Not set"
  }

}

const MonthlyItem = ({ item }) => {
  const timeFormat = (x) => {
    let date = new Date(x);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const getFormattedTime = (time) => {
    let hour = 0;
    let min = 0;
    let sec = 0;

    if (time > 3600) {
      hour = parseInt(time / 3600);
    }

    let remainingTime = time % 3600;

    if (remainingTime > 60) {
      min = parseInt(remainingTime / 60);
    }
    sec = remainingTime % 60;

    if (time > 3600) {
      return hour + " hrs " + min + " min";
    }

    return min + " min " + sec + " sec";
  };

 
  return (
    <ListItem disablePadding divider>
      <Accordion style={{ padding: 0, width: "100%",overflowX:"hidden" }} disableGutters>
        <AccordionSummary
          style={{ padding: 0, margin: 0 }}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="list-item">
            <div className="left-day">
              <p>{item._id.day}</p>
            </div>
            <div className="right-content">
              <Typography variant="inherit">
                {(item.distance / 1000).toFixed(2)+" KM"}
              </Typography>

              <Tooltip title="Daily Report">
                <IconButton>
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <div className="monthly-data">
            <p>Start Time</p>
            <p>{timeFormat(item.start_time)}</p>

            <p>End Time</p>
            <p>{timeFormat(item.end_time)}</p>

            <p>Idle Time</p>
            <p>{getFormattedTime(item.idle_time)}</p>

            <p>Running Time</p>
            <p>{getFormattedTime(item.running_time)}</p>

            <p>Congestion Time</p>
            <p>{getFormattedTime(item.congestion_time)}</p>

            <p>Fuel Consumption</p>
            <p>{getFuel(item.distance)}</p>
          </div>
         
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

const MonthlyList = ({ data }) => {
  return (
    <List disablePadding style={{ width: "100%" }} className="bal">
      {data.map((x, i) => (
        <MonthlyItem key={i} item={x} />
      ))}
    </List>
  );
};

const MonthlyReport = () => {
  const [date, setDate] = useState(new Date());
  const [MonthlyData, setMonthlyData] = useState([]);
  const [progressVisible,setProgressVisible] = useState(false)
  const Device = JSON.parse(localStorage.getItem("Device"));

  // console.log(Device)

  const fetchMonthlyData = () => {
    setProgressVisible(true)
    let data = {
      device_id: Device.id,
      year: date.getFullYear(),
      month: date.getMonth(),
    };

    axios
      .post(MONTHLY_LOCATIONS_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProgressVisible(false)
        setMonthlyData(response.data);
      })
      .catch((err) => {
        setProgressVisible(false)
        console.log(err)
      });
  };

  useEffect(() => {
    fetchMonthlyData();
  }, [date]);

  const prevClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() - 1);
      return bbb;
    });
  };

  const nextClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() + 1);
      return bbb;
    });
  };

  const downloadFile = () => {
    setProgressVisible(true)
    let data = {
      device: Device,
      year: date.getFullYear(),
      month: date.getMonth(),
    };
    axios
      .post(MONTHLY_FILE_URL, data, { responseType: "blob" })
      .then((response) => {
        setProgressVisible(false)
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) =>{
        setProgressVisible(false)
        console.log(err)
      } );
  };

 

  return (
    <div className="main-container">
      
      <TitleBar title="Monthly Report" visible={true} downloadFile={downloadFile} />

      <div style={{position: "fixed",
    top:64,
    left: 0,
    right: 0,
    padding: 10,
    backgroundColor: "#1976d2ff"}}>
      <div className="btn-container">
          <div className="btn-x">
            <Tooltip title="Previous">
              <IconButton onClick={prevClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div className="middle-box">
            <Typography variant="h6">{date.toLocaleDateString("us-EN", {year:"numeric",month:"long"})}</Typography>
          </div>

          <div className="btn-x">
            <Tooltip title="Next">
              <IconButton onClick={nextClick}>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <div style={{position:"fixed",left:0,right:0,top:126,bottom:0}}>
        <div className="list-container">
          <MonthlyList data={MonthlyData} />
        </div>

      </div>

    
      {progressVisible && <div className="progress-container">
          <CircularProgress color="secondary"  />
        </div>}

    


      {/* <div className="container">
        <div className="btn-container">
          <div className="btn-x">
            <Tooltip title="Previous">
              <IconButton onClick={prevClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div className="middle-box">
            <Typography variant="h6">{date.toLocaleDateString("us-EN", {year:"numeric",month:"long"})}</Typography>
          </div>

          <div className="btn-x">
            <Tooltip title="Next">
              <IconButton onClick={nextClick}>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="info-container">
          <div className="header">
            <Typography variant="button">Distance</Typography>
            <Typography variant="button">Fuel</Typography>
          </div>
          <div className="header">
            <Typography variant="button">{(MonthlyData.reduce((pv, cv) => pv + cv.distance, 0)/1000).toFixed(0)+" KM"}</Typography>
            <Typography variant="button">{getFuel(MonthlyData.reduce((pv, cv) => pv + cv.distance, 0))}</Typography>
          </div>
        </div>
      </div>

      <div className="list-container">
        <MonthlyList data={MonthlyData} />
      

      
      </div>

      {progressVisible && <div className="progress-container">
          <CircularProgress color="secondary"  />
        </div>} */}
    </div>
   
  );
};
export default MonthlyReport;
