import React, { useState, useEffect } from "react";
import {Button,Typography,IconButton,Tooltip} from '@mui/material'
import {useHistory} from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout';
import DownloadIcon from '@mui/icons-material/Download';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import axios from "axios";
import JalaliDatePicker from "./date-picker";


import './css/title_bar.css'


const TitleBar=({title,visible,downloadFile,calendarVisible,calendarClick,date,setDate})=>{

const history = useHistory();

  const logoutClicked = () => {
    localStorage.removeItem("Device");
    history.push("/login");
  };

  const downloadClick=()=>{
      console.log("Download Click")
      downloadFile()
  }

 


    return(
        <div className="app-bar">
        <Typography
            variant="h6"
          
          >{title?title:"Live Tracking"}</Typography>
          <div className="btn">
             {visible &&  <Tooltip title="Download">
                  <IconButton onClick={downloadClick}>
                      <DownloadIcon/>
                  </IconButton>
              </Tooltip>}

              {calendarVisible &&  <JalaliDatePicker value={date} setValue={setDate}/>}

              <Tooltip title="Logout">
                  <IconButton onClick={logoutClicked}>
                      <LogoutIcon/>
                  </IconButton>
              </Tooltip>

          </div>
        {/* <Button color="inherit" onClick={() => logoutClicked()}>Logout</Button> */}
        
      </div>
    )
}

export default TitleBar