import React from "react";
import Home from "./home";
import Login from "./login";
import GMap from "./map";
import Monthlyreport from "./report";
import DailyReport from "./DailyReport/dailyReport";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";

const Main = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/map" component={GMap} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/report" component={Monthlyreport} />
          <Route exact path="/dailyreport" component={DailyReport} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Main;
