export const HOURS = [
  {
    hour: "1st",
  },
  {
    hour: "2nd",
  },
];

export const getFormattedTime = (time) => {
  let hour = 0;
  let min = 0;
  let sec = 0;

  if (time > 3600) {
    hour = parseInt(time / 3600);
  }

  let remainingTime = time % 3600;

  if (remainingTime > 60) {
    min = parseInt(remainingTime / 60);
  }
  sec = remainingTime % 60;

  if (time > 3600) {
    return hour + " hrs " + min + " min";
  }

  return min + " min " + sec + " sec";
};


export const timeFormat = (dateStr) => {
  // let date = new Date(x);
  
  let arr = dateStr.split(":")
  let hours = parseInt(arr[0])
  let minutes = parseInt(arr[1])
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};


export const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}


export const getFuel =(distance)=>{
  let device = JSON.parse(localStorage.getItem("Device"))
  if(device.milage){
    let d = distance/1000
    return (d/device.milage).toFixed(2)+" Lit"
  }else{
    return "Milage Not set"
  }

}
