import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CommuteIcon from '@mui/icons-material/Commute';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function DailyBottomNavigation({value, setValue}) {

  return (
    <Box style={{position:"fixed",left:0,right:0,bottom:0}}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
      
        <BottomNavigationAction label="Trip Report" icon={<CommuteIcon />} />
        <BottomNavigationAction label="Hourly Report" icon={<HourglassBottomIcon />} />
        <BottomNavigationAction label="Chart" icon={<BarChartIcon />} />
      </BottomNavigation>
    </Box>
  );
}
