import React from "react";
import { Box } from "@mui/material";
import Login from "./login";
import Map from "./map";



const Home = ({ device }) => {
  const Device = JSON.parse(localStorage.getItem("Device"));

  return <Box>{Device == null ? <Login /> : <Map device={device} />}</Box>;
};



export default Home
