import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import BarChartIcon from "@mui/icons-material/BarChart";
import NavBar from "./nav";
import Googlemap from "./gmap";
import { useHistory } from "react-router";

const Map = () => {
  const device = JSON.parse(localStorage.getItem("Device"));
  const history = useHistory();
  const monthlyClicked = () => {
    history.push("/report");
  };
  const dailyClicked = () => {
    history.push("/dailyreport");
  };
 

  return (
    <Box>
      <NavBar />
      <Googlemap  /> 
      <Button
        title="Monthly Report"
        style={{
          position: "absolute",
          margin: 10,
          right: 50,
          bottom: 50,
          backgroundColor: "#113CFC",
          borderTopLeftRadius: 100,
          borderTopRightRadius: 100,
          borderBottomLeftRadius: 100,
          borderBottomRightRadius: 100,
        }}
        onClick={() => {
          monthlyClicked();
        }}
      >
        <TimelineIcon />
      </Button>
      <Button
        title="Daily Report"
        style={{
          position: "absolute",
          margin: 10,
          right: 50,
          bottom: 120,

          backgroundColor: "#113CFC",
          borderTopLeftRadius: 100,
          borderTopRightRadius: 100,
          borderBottomLeftRadius: 100,
          borderBottomRightRadius: 100,
        }}
        onClick={() => {
          dailyClicked();
        }}
      >
        <BarChartIcon />
      </Button>
    </Box>
  );
};



export default Map
