import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from "@mui/material/Stack";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

export default function BasicDatePicker({value,setValue}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Select Report Date"
        // inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(newValue) => {
          setValue(new Date(newValue));
        }}
        renderInput={(params) => {
          return <TextField {...params} />
        }}
      />
    </LocalizationProvider>

        //     <LocalizationProvider dateAdapter={AdapterDateFns}>
        //   <Stack spacing={3}>
        //     <DesktopDatePicker
        //       label="Pick Up Your Date "
        //       inputFormat="MM/dd/yyyy"
        //       value={value}
        //       onChange={setValue}
        //       renderInput={(params) => <TextField {...params} />}
        //     />
        //   </Stack>
        // </LocalizationProvider>
  );
}