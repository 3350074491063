import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
// import { connect } from "react-redux";
// import { setUserDevice } from "../redux/action/device_action";

const NavBar = ({title}) => {
  const history = useHistory();

  const logoutClicked = () => {
    localStorage.removeItem("Device");
    history.push("/login");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1,textAlign:"start" }}
          >{title?title:"Live Tracking"}</Typography>
          <Button color="inherit" onClick={() => logoutClicked()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};


export default NavBar;
